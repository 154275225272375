/* eslint-disable max-lines */
import { useRouter } from 'next/router';
import { MutableRefObject, useCallback, useMemo, useEffect, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { NormalizedProduct, NormalizedVariant, NormalizedCollection } from '@ts/product';
import { ProductView } from '@components';
import { isEmpty } from '@utils/objects';
import { useProductQuery } from '@services/shopify';
import {
	ADD_TO_CART,
	ALL_BASE_FRAME_HANDLES,
	BADGE_TYPES,
	BASE_FRAME_NAMES,
	FRAME_COLOR_HANDLES,
	FRAME_COLORS,
	LARGE_WIDTH,
	LOCALE_CODES,
	LOCALE_DICT,
	NEW_COLORS,
	TOP_FRAMES_PATH,
} from '@constants';
import { useDiscountedPrice, useIsMobile, useIsProductVariantSoldOut } from '@utils/hooks';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { useCartContext } from '@context';
import { getAllBaseFrames } from '@services/contentful';
import { updateProductVariantPrice } from '@utils/shopify';
import styles from './TopSidebar.module.scss';

type TopSidebarProps = {
	product: NormalizedProduct;
	productTags;
	yotpo;
	baseFrameVariantsAvailable;
	frameShape: (typeof BASE_FRAME_NAMES)[number];
	frameSwatches;
	setFrameShape;
	activeFrameColor;
	handleActiveFrameColorChange;
};

function TopSidebar({
	product,
	frameShape,
	setFrameShape,
	productTags,
	yotpo,
	frameSwatches,
	baseFrameVariantsAvailable,
	activeFrameColor,
	handleActiveFrameColorChange,
}: TopSidebarProps) {
	const { asPath, pathname, query, replace, locale } = useRouter();
	const { handleCartAdd, isCartMutating, getSubscriptionItem } = useCartContext();
	const isMobile = useIsMobile({ maxWidth: LARGE_WIDTH });
	const [baseFrameProducts, setBaseFrameProducts] = useState<NormalizedCollection>(null);
	const isHideNewColors = useFeatureIsOn('is-hide-new-products');
	const isUS = locale === LOCALE_CODES.US;
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const hasSubscriptionItem = useMemo(() => !!getSubscriptionItem(frameShape), [frameShape, getSubscriptionItem]);
	const subscriptionCTALabel = hasSubscriptionItem ? `${frameShape} Subscription already in Cart` : 'Subscribe and Save';
	const { description, name, price, type, descriptionHtml } = product;

	const activeBaseFrame = Object.entries(ALL_BASE_FRAME_HANDLES).find(([handle, frame]) => frame === frameShape && handle)[0];

	const formattedColor = activeFrameColor.toLowerCase().replace(/\s+/g, '-');
	const activeBaseFrameHandle = activeBaseFrame
		? `${activeBaseFrame}-${formattedColor as keyof typeof FRAME_COLOR_HANDLES}`
		: null;

	const { data: baseFrameData } = useProductQuery(
		activeBaseFrameHandle,
		{ includeDescription: false, skipCollections: true, skipVariants: true },
		{
			queryKey: `base-frame-variant-${frameShape}`,
			queryRefreshVars: [activeBaseFrameHandle, frameShape],
		}
	);

	useEffect(() => {
		(async () => {
			const allBaseFrame = (await getAllBaseFrames(true)) as NormalizedCollection;
			setBaseFrameProducts(allBaseFrame);
		})();
	}, []);

	const activeBaseFrameProduct = useMemo(() => {
		if (!baseFrameProducts || !activeBaseFrame) return null;
		return baseFrameProducts.products.find(product => product.handle === activeBaseFrame) || null;
	}, [baseFrameProducts, activeBaseFrame]);

	const { data: variant, isLoading }: { data: NormalizedVariant; isLoading: boolean } = useProductQuery(
		product.handle,
		{
			selectedOptions: [{ name: 'Frame', value: frameShape }],
			includeSpecificFrameVariant: true,
			country: LOCALE_DICT[locale].countryCode,
		},
		{
			queryKey: 'top-frame-variants',
			queryRefreshVars: [frameShape, product.handle, locale],
		}
	);

	const shipmentInfo = variant?.metafields?.shipmentInfo;

	const updateFrameShape = useCallback(
		(frame: (typeof BASE_FRAME_NAMES)[number]) => {
			setFrameShape(frame);
			const newQuery = { ...query, frameShape: frame };
			replace({ pathname, query: newQuery }, undefined, { shallow: true });
		},
		[pathname, query, replace]
	);

	const { checkIfSoldOut } = useIsProductVariantSoldOut();
	const isSoldOut = checkIfSoldOut(product.handle, variant?.option);

	const subscriptionPlan = useMemo(() => {
		return product.sellingPlans.find(plan => plan.name.includes('1 Month'));
	}, [product]);

	const handleAdd = useCallback(
		({ ref, sellingPlanId }: { ref?: MutableRefObject<HTMLButtonElement | null>; sellingPlanId?: string }) => {
			handleCartAdd(
				[
					{
						variant: !isLoading && variant,
						customAttributes: [
							{
								key: CART_LINE_ATTRIBUTE_KEYS.COLLECTION_PATH,
								value: `${TOP_FRAMES_PATH}/${query?.collection}/`,
							},
							isV2Subscription && !!sellingPlanId && { key: '_custom_subscription_discount', value: '15.0' },
						].filter(Boolean),
						sellingPlanId: sellingPlanId,
					},
				],
				true,
				ref
			);
		},
		[handleCartAdd, isLoading, variant, query]
	);
	const [newPrice, newCompareAtPrice, isDiscountEligible] = useDiscountedPrice({
		price: price.amount,
		compareAtPrice: variant?.compareAtPrice?.amount,
		type: product.type,
	});

	let colorOptions = baseFrameData ? baseFrameData.metafields?.frameColors?.options : [];

	if (isHideNewColors) {
		colorOptions = colorOptions.filter(v => !NEW_COLORS.includes(v as FRAME_COLORS));
	}

	return (
		<div>
			<ProductView.Sidebar
				path={asPath}
				product={product}
				selectedVariant={!isLoading && updateProductVariantPrice(variant, newPrice, newCompareAtPrice)}
				priceTag={isDiscountEligible && BADGE_TYPES.MEMBERS_DISCOUNT}
				name={name}
				subtotal={Number(newPrice)}
				updateFrameShape={updateFrameShape}
				productType={type}
				productTags={productTags}
				yotpo={yotpo}
				shipmentInfo={shipmentInfo}
				primaryController={{
					controllerType: 'shape',
					title: 'Base Frame Shape',
					buttonProps: {
						fullWidth: true,
						color: 'transparent-dark',
						withChevron: true,
						chevronDirection: 'down',
						label: frameShape,
						spaceBetween: true,
					},
				}}
				colorController={{
					controllerType: 'frame',
					title: frameShape,
					selected: activeFrameColor,
					options: colorOptions,
					variantImages: activeBaseFrameProduct ? activeBaseFrameProduct?.variantImages : [],
					callback: handleActiveFrameColorChange,
				}}
				collectionController={
					!isEmpty(frameSwatches) && {
						controllerType: 'option',
						title: 'More From This Collection',
						options: Object.keys(frameSwatches[frameShape]),
						selected: product?.metafields?.productSwatch?.url,
						frameSwatches: frameSwatches[frameShape],
					}
				}
				aboutProps={{
					description: description,
					descriptionHtml: descriptionHtml,
					name: name,
					productType: type,
					selectedFrame: frameShape,
				}}
				buttonProps={{
					label: isSoldOut ? 'Sold out!' : `+ ${ADD_TO_CART}`,
					onClick: ref => handleAdd({ ref }),
					disabled: isCartMutating || isSoldOut,
					hasSubtotal: false,
					withChevron: false,
					price: newPrice,
					currencyCode: price.currencyCode,
					dataTags: { 'data-add-to-cart': name },
					availableForSale: !isLoading ? variant?.availableForSale : false,
					margin: isMobile ? '0.8rem 0 0' : '1.6rem 0 0',
				}}
				secondaryButtonProps={
					!!subscriptionPlan?.price &&
					isV2Subscription &&
					isUS && {
						label: subscriptionCTALabel,
						onClick: () => handleAdd({ sellingPlanId: subscriptionPlan.id }),
						price: subscriptionPlan.price.amount,
						compareAtPrice: product.price.amount,
						currencyCode: subscriptionPlan.price.currencyCode,
						fullWidth: true,
						withPrice: true,
						color: 'white',
						size: 'large',
						disabled: hasSubscriptionItem,
						showPriceWithLabel: true,
						extraClasses: styles.subscriptionButton,
						dataTags: { 'data-add-to-cart': product.name, 'data-subscribe-and-save': product.name },
					}
				}
				variantsAvailable={new Set(baseFrameVariantsAvailable)}
				frameShape={frameShape}
			/>
		</div>
	);
}

export default TopSidebar;
